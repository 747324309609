import "./Content3.css";
import Content3Top from '../../components/Content3Top/Content3Top';
import Content3Main from '../../components/Content3Main/Content3Main';
import Sidebar from "../Sidebar/Sidebar";


const Content3 = () => {
  return (
    <div className='main-content2'>
        <Content3Top/>
        <Content3Main/>
    </div>
  )
}

export default Content3