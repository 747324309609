import "./Content8.css";
import Content8Top from '../../components/Content8Top/Content8Top';
import Content8Main from '../../components/Content8Main/Content8Main';



const Content8 = () => {
  return (
    <div className='main-content2'>
        <Content8Top/>
        <Content8Main/>
    </div>
  )
}

export default Content8