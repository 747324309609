import "./Content2.css";
import Content2Top from '../../components/Content2Top/Content2Top';
import Content2Main from '../../components/Content2Main/Content2Main';
import Sidebar from "../Sidebar/Sidebar";


const Content2 = () => {
  return (
    <div className='main-content2'>
        <Content2Top/>
        <Content2Main/>
    </div>
  )
}

export default Content2