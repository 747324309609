import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";


const firebaseConfig = {
    apiKey: "AIzaSyAuZhHI_lqAYxch70orLFo6SbRwSXn4Spg",
    authDomain: "english-app-666e8.firebaseapp.com",
    projectId: "english-app-666e8",
    storageBucket: "english-app-666e8.appspot.com",
    messagingSenderId: "723265857340",
    appId: "1:723265857340:web:c4fc347b61744b96eaa039",
    measurementId: "G-VFZKX9YLHY"
  };


// Initialize Firebase

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
export const auth = firebase.auth();
export default db;