import "./Content8Main.css";
import { FaCheckCircle } from "react-icons/fa";
// import Upcomingclasses from "../ContentMain/Upcomingclasses";


const Content8Main = () => {
  const openURL = () => {
    const url = 'https://www.facebook.com/profile.php?id=61561084893176&mibextid=LQQJ4d'
    window.open(url, "_blank", "noreferrer")
  }
  return (
    <div className="main-content-holder1">
      {/* <h3 className="main-text">Upcoming Classes</h3>
      <hr/> */}
      {/* <Upcomingclasses/> */}
      
       {/* <h4 className="textAbout">අමතන්න / Call Us - 0773160677</h4>
      
      <h4 className="textAbout">Or</h4>
      <button className="fbButton" onClick={openURL}>Visit our facebook page</button> */}
      {/* <p>Last updated: August 20, 2024</p>
      <p>Thank you for choosing our platform for your spoken English practice. We strive to provide a high-quality learning experience and appreciate your understanding of our refund policy.</p> */}

      
      {/* <h2>Refund Eligibility:</h2> */}
      <div className="comp1">
       <FaCheckCircle className="iconn2" size={'70px'}/>
      </div>
      <h4 className="texttop">ස්තූතියි !</h4>
      <h4 className="textt1">ඔබගේ පැකේජය සුළු මොහොතකින් සක්‍රීය වනු ඇත. විමසීම්: 0773160677</h4>

      <h4 className="textbottm">Thank you !</h4>
      <h4 className="textt1">We will activate your package within few minutes. For any clarification contact : 0773160677</h4>
      
      
      
    </div>
  )
}

export default Content8Main