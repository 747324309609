import accountIMG from "../../assets/user (1).png";
// import ChooseMeals from "../Assets/choose-image.png";
// import DeliveryMeals from "../Assets/delivery-image.png";

const Work = () => {
  const workInfoData = [
    {
      image: accountIMG,
      title: "Sachin Liyanage",
      text: "\"කාලයක් තිස්සෙ මම ගොඩක් ඉංග්‍රීසි ක්ලාස් වලට ගිහින් කාලෙ නාස්ති කරා මට ඒත් ඉංග්‍රීසි කතා කරගන්න බැරි වුනා . මේකෙ රෙජිස්ටර් වෙලා සතියක් කතා කරන කොටම මට ලොකු වෙනසක් තේරුනා\"",
    },
    {
      image: accountIMG,
      title: "Dananjaya Perera",
      text: "\"මම කොච්චර ඉංග්‍රීසි ඉගෙන ගත්තත් මට හරියට ඉංග්‍රීසි කතා කර ගන්න බැරි උන එකට හේතුව වුනේ මට ඉංග්‍රීසි කතා කරල පුරුදු වෙන්න කවුරුත් හිටියේ නැති එක. එතකොට තමයි මම KaddaWeb එක ගැන දැන ගත්තේ. ඒකෙ ඉන්න උපදේශකවරු ගොඩක් හොඳයි.\"",
    },
    {
      image: accountIMG,
      title: "Shehanie Fernando",
      text: "\"වැරදුනාට කවුරුත් හිනා වෙන්නේ නැති නිසා මට දැන් බය නැතුව ඉංග්‍රීසි කතා කරල පුරුදු වෙන්න පුලුවන්. Thank u KaddaWeb ❤️\"",
    }
  ];
  return (
    <div className="work-section-wrapperL">
      <div className="work-section-topL">
        {/* <p className="primary-subheadingL">Work</p> */}
        <h1 className="primary-headingL2">KaddaWeb භාවිතා කරන්නන්ගේ අදහස්</h1>
        {/* <p className="primary-textL">
          Lorem ipsum dolor sit amet consectetur. Non tincidunt magna non et
          elit. Dolor turpis molestie dui magnis facilisis at fringilla quam.
        </p> */}
      </div>
      <div className="work-section-bottomL">
        {workInfoData.map((data) => (
          <div className="work-section-infoL" key={data.title}>
            <div className="info-boxes-img-containerL">
              <img src={data.image} alt="" className="imL"/>
            </div>
            <div>
            <h2>{data.title}</h2>
            <p>{data.text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Work;