import "./Content4.css";
import Content4Top from '../../components/Content4Top/Content4Top';
import Content4Main from '../../components/Content4Main/Content4Main';
//import Sidebar from "../Sidebar/Sidebar";


const Content4 = () => {
  return (
    <div className='main-content2'>
        <Content4Top/>
        <Content4Main/>
    </div>
  )
}

export default Content4