import "./ContentMain.css";
import Upcomingclasses from "./Upcomingclasses";


const ContentMain = () => {
  return (
    <div className="main-content-holder">
      <h3 className="main-text">පන්ති / Upcoming Classes</h3>
      <hr/>
      <Upcomingclasses/>
    </div>
  )
}

export default ContentMain