// import ProfilePic from "../Assets/john-doe-image.png";
import { Link } from "react-router-dom";
import { AiFillStar } from "react-icons/ai";
import { FiArrowRight } from "react-icons/fi";
const Testimonial = () => {
  return (
    <div className="work-section-wrapperL2">
      <div className="work-section-topL">
        {/* <p className="primary-subheadingL">Testimonial</p> */}
        <h1 className="primary-headingL2">අපි KaddaWeb සෑදුවේ ඇයි ?</h1>
        {/* <p className="primary-textL">
          Lorem ipsum dolor sit amet consectetur. Non tincidunt magna non et
          elit. Dolor turpis molestie dui magnis facilisis at fringilla quam.
        </p> */}
      </div>
      {/* <div className="testimonial-section-bottomL">
        <img src={ProfilePic} alt="" />
        <p>
        ලංකාවේ බොහෝ දෙනෙකුගේ ඉංග්‍රීසි කතා කිරීම ඉතා අඩු මට්ටමක පවතින බැවින් ඔවුන් ඉංග්‍රීසි කතා කිරීමේදී අපහසුතාවයන්ට ලක්වේ. ඔවුන් එයට විසඳුමක් ලෙස නොයෙකුත් ඉංග්‍රීසි පන්ති/ සම්මන්ත්‍රණ/ දේශණ වලට සහභාගි වුවත් ඔවුන්ගේ අවශ්‍යතාවය ඉටු නොවේ. මෙයට ඇති සරල විසදුම නම් ඉංග්‍රීසි කතා කර පුරුදු වීම පමණි නමුත් මෙය සඳහා බොහෝ දෙනෙකුට අවස්ථාවක් නොමැති බැවින් එයට විසඳුමක් ලෙස අප KaddaWeb සාදා ඇත. KaddaWeb සමග සම්බන්ධ වීම සඳහා දැන්ම ගිණුමක් සාදන්න !
        </p>
        <button className="secondary-buttonL">
            Order Now <FiArrowRight />{" "}
        </button>
        <div className="testimonials-stars-containerL">
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
        </div>
        <h2>John Doe</h2>
      </div> */}
      <div className="testBottom">
        <p>ලංකාවේ බොහෝ දෙනෙකුගේ ඉංග්‍රීසි කතා කිරීම ඉතා අඩු මට්ටමක පවතින බැවින් ඔවුන් ඉංග්‍රීසි කතා කිරීමේදී අපහසුතාවයන්ට ලක්වේ. ඔවුන් එයට විසඳුමක් ලෙස නොයෙකුත් ඉංග්‍රීසි පන්ති/ සම්මන්ත්‍රණ/ දේශණ වලට සහභාගි වුවත් ඔවුන්ගේ අවශ්‍යතාවය ඉටු නොවේ. මෙයට ඇති සරල විසදුම නම් ඉංග්‍රීසි කතා කර පුරුදු වීම පමණි නමුත් මෙය සඳහා බොහෝ දෙනෙකුට අවස්ථාවක් නොමැති බැවින් එයට විසඳුමක් ලෙස අප KaddaWeb සාදා ඇත.</p>
        <p>KaddaWeb සමග සම්බන්ධ වීම සඳහා දැන්ම ගිණුමක් සාදන්න !</p>
        <Link className="secondary-buttonL" to="/SignUp">
        ගිණුමක් සාදන්න <FiArrowRight />{" "}
        </Link>
      </div>
    </div>
  );
};

export default Testimonial;