import "./Content4Main.css";
// import Upcomingclasses from "../ContentMain/Upcomingclasses";


const Content4Main = () => {
  const openURL = () => {
    const url = 'https://www.facebook.com/profile.php?id=61561084893176&mibextid=LQQJ4d'
    window.open(url, "_blank", "noreferrer")
  }
  return (
    <div className="main-content-holder">
      {/* <h3 className="main-text">Upcoming Classes</h3>
      <hr/> */}
      {/* <Upcomingclasses/> */}
      
       <h4 className="textAbout">අමතන්න / Call Us - 0773160677</h4>

       <h4 className="textAbout">Email Us - kaddawebcom@gmail.com</h4>

       <h4 className="textAbout">Address - No.656, Lake Road, Boralesgamuwa, Sri Lanka</h4>
      
      {/* <h4 className="textAbout">Or</h4>
      <button className="fbButton" onClick={openURL}>Visit our facebook page</button> */}
    </div>
  )
}

export default Content4Main