import './App.css';
import {auth} from "./firebase"
import { useSelector } from "react-redux";
import Sidebar from './layout/Sidebar/Sidebar';
import Content from './layout/Content/Content';
import { BrowserRouter,Router,Route,Routes } from 'react-router-dom';
import Content2 from './layout/Content2/Content2';
import Content3 from './layout/Content3/Content3';
import Login from './components/Login';
import SignUp from './components/SignUp';
import { useEffect, useState } from 'react';
import {
  selectUserName,
} from "./features/user/userSlice";
import Content4 from './layout/Content4/Content4';
import Content5 from './layout/Content5/Content5';
import Content6 from './layout/Content6/Content6';
import Home from './components/Home';
import Content7 from './layout/Content7/Content7';
import Content8 from './layout/Content8/Content8';

function App() {

  const [logged, setLogged] = useState(false)
  const [ready, setReady] = useState(false)
  const userName = useSelector(selectUserName);

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        setLogged(true);
        setReady(true);
      } else {
        setLogged(false);
        setReady(true);
      }
    });
  }, [userName]);

  return (
  <BrowserRouter>
    { logged ? (<>
      <div className='app'>
        <Sidebar />
        <Routes>
          <Route path="/" element={<Content />}></Route>
          <Route path="/Payment-process"element={<Content8/>}></Route>
          <Route path="/Packages"element={<Content2/>}></Route>
          <Route path="/About"element={<Content3/>}></Route>
          <Route path="/Contact"element={<Content4/>}></Route>
          <Route path="/Terms-of-use"element={<Content5/>}></Route>
          <Route path="/Privacy-policy"element={<Content6/>}></Route>
          <Route path="/Refund-policy"element={<Content7/>}></Route>
        </Routes>
      </div>
    </>) : (
     <Routes>
      {ready ? (<Route path="/" element={<Login/>}></Route>) : null}
      <Route path="/SignUp"element={<SignUp/>}></Route>
      <Route path="/Home"element={<Home/>}></Route>
     </Routes>
    )}
  </BrowserRouter>
  
  )
}

export default App
