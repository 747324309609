import "./Content5.css";
import Content5Top from '../../components/Content5Top/Content5Top';
import Content5Main from '../../components/Content5Main/Content5Main';



const Content5 = () => {
  return (
    <div className='main-content2'>
        <Content5Top/>
        <Content5Main/>
    </div>
  )
}

export default Content5