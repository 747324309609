import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  upcoming: null,
};

const classCallsSlice = createSlice({
  name: "call",
  initialState,
  reducers: {
    setCalls: (state, action) => {
      state.upcoming = action.payload.upcoming;
    },
  },
});

export const { setCalls } = classCallsSlice.actions;

export const selectUpcoming = (state) => state.call.upcoming;

export default classCallsSlice.reducer;