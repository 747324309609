import { iconsImgs } from "../utils/images";
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import CallRoundedIcon from '@mui/icons-material/CallRounded';
import AlternateEmailRoundedIcon from '@mui/icons-material/AlternateEmailRounded';
import AppsRoundedIcon from '@mui/icons-material/AppsRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';
import ArticleIcon from '@mui/icons-material/Article';
import PolicyIcon from '@mui/icons-material/Policy';

export const navigationLinks = [
    { id: 1, title: 'Home', image: iconsImgs.home, goto: "/", icon: <HomeRoundedIcon/> },
    { id: 2, title: 'Packages', image: iconsImgs.budget, goto: "/Packages", icon: <AppsRoundedIcon/> },
    { id: 3, title: 'About', image: iconsImgs.budget, goto: "/About", icon: <LanguageRoundedIcon/> },
    { id: 4, title: 'Contact Us', image: iconsImgs.budget, goto: "/Contact", icon: <CallRoundedIcon/> },
    { id: 5, title: 'Terms of Use', image: iconsImgs.budget, goto: "/Terms-of-use", icon: <ArticleIcon/> },
    { id: 6, title: 'Refund Policy', image: iconsImgs.budget, goto: "/Refund-policy", icon: <ArticleIcon/> },
    { id: 7, title: 'Privacy Policy', image: iconsImgs.budget, goto: "/Privacy-policy", icon: <PolicyIcon/> }
    
]; 