import "./Home.css";
import { useEffect } from 'react';
import LHome from "./LandingPage/LHome";
import Testimonial from "./LandingPage/Testimonial";
import Work from "./LandingPage/Work";

const Home = () => {
//   useEffect(() => {
//     window.scrollTo(0, 0)
//   }, [])


  return (
    <div className='AppL'>
       <LHome />
       <Work />
       <Testimonial />
    </div>
  )
}

export default Home