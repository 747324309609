import "./Content7.css";
import Content7Top from '../../components/Content7Top/Content7Top';
import Content7Main from '../../components/Content7Main/Content7Main';



const Content7 = () => {
  return (
    <div className='main-content2'>
        <Content7Top/>
        <Content7Main/>
    </div>
  )
}

export default Content7