import "./Content5Main.css";
// import Upcomingclasses from "../ContentMain/Upcomingclasses";


const Content5Main = () => {
  const openURL = () => {
    const url = 'https://www.facebook.com/profile.php?id=61561084893176&mibextid=LQQJ4d'
    window.open(url, "_blank", "noreferrer")
  }
  return (
    <div className="main-content-holder">
      {/* <h3 className="main-text">Upcoming Classes</h3>
      <hr/> */}
      {/* <Upcomingclasses/> */}
      
       {/* <h4 className="textAbout">අමතන්න / Call Us - 0773160677</h4>
      
      <h4 className="textAbout">Or</h4>
      <button className="fbButton" onClick={openURL}>Visit our facebook page</button> */}
      <p>To schedule sessions, student must have an active package with sessions remaining. Remaining session count can be seen from the “Dashboard”.</p>
      <p>Any student can buy packages based on their learning requirements. Each package contains one or more sessions and price varies based on the number of sessions. All available packages to purchase are displayed in “Packages” menu.</p>
      <p>Price of each package is displayed in the relative area. Students who wish to purchase may select the required item and proceed with the online payment options given.</p>
      <p>Students can reactivate or change their package once the total number of sessions are scheduled.</p>
      <p>Once a session is scheduled, student can join the session via the platform and session is conducted via Zoom. Student needs to join the session on time.</p>
      <p>If a student is unable to join a scheduled session, student can reschedule 6 hours before the scheduled time. If a student is unable to join and is not rescheduled, the session is considered as complete.</p>
      <p>For more information, student must directly contact us via</p>
      <p>+94 77 31 60 677</p>
    </div>
  )
}

export default Content5Main