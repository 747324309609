import "./Sidebar.css";
import { auth } from "../../firebase";
import {personsImgs} from "../../utils/images"
import {navigationLinks} from "../../data/data"
import { useContext } from 'react';
import { SidebarContext } from '../../context/sidebarContext';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from "react-redux";
import check from "../../components/kad-logo2.png"
import {
    selectUserName,
    selectUserPhoto,
    selectUserEmail
  } from "../../features/user/userSlice";

const Sidebar = () => {
    //const [activeLinkIdx, setActiveLinkIdx] = useState(1);
    const [sidebarClass, setSidebarClass] = useState("");
    const { isSidebarOpen } = useContext(SidebarContext);

    // const userEmail = useSelector(selectUserEmail);
    const userEmail = auth?.currentUser?.email
    //console.log(userEmail, '<-user');

    useEffect(() => {
        if(isSidebarOpen){
          setSidebarClass('sidebar-change');
        } else {
          setSidebarClass('');
        }
      }, [isSidebarOpen]);

    
    return (
    <div className={`sidebar ${sidebarClass}`}>
        <div className='imgr'>
              <img src={check} className='imagge-1'/>
            </div>
        <div className="user-info">
            {/* <div className="info-img img-fit-cover">
                <img src={personsImgs.person_two} alt="profile image"/>
            </div> */}
            <span className="info-name">{userEmail}</span>
        </div>
        <nav className="navigation">
            <ul className="nav-list">
                {
                    navigationLinks.map((navigationLink) =>(
                        <li className="nav-item" key={
                            navigationLink.id
                        }>
                            <NavLink to={navigationLink.goto} className={ `nav-link` } >
                                {/* <img src = {navigationLink.image}
                                className="nav-link-icon" alt={navigationLink.title}/> */}
                                <div className="nav-link-icon">{navigationLink.icon}</div>
                                <span className="nav-link-text">
                                    {navigationLink.title}
                                </span>
                            </NavLink>
                        </li>
                    ))
                }
            </ul>
        </nav>
    </div>
  )
}

export default Sidebar