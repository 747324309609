import "./Content7Main.css";
// import Upcomingclasses from "../ContentMain/Upcomingclasses";


const Content7Main = () => {
  const openURL = () => {
    const url = 'https://www.facebook.com/profile.php?id=61561084893176&mibextid=LQQJ4d'
    window.open(url, "_blank", "noreferrer")
  }
  return (
    <div className="main-content-holder">
      {/* <h3 className="main-text">Upcoming Classes</h3>
      <hr/> */}
      {/* <Upcomingclasses/> */}
      
       {/* <h4 className="textAbout">අමතන්න / Call Us - 0773160677</h4>
      
      <h4 className="textAbout">Or</h4>
      <button className="fbButton" onClick={openURL}>Visit our facebook page</button> */}
      <p>Last updated: August 20, 2024</p>
      <p>Thank you for choosing our platform for your spoken English practice. We strive to provide a high-quality learning experience and appreciate your understanding of our refund policy.</p>

      
      {/* <h2>Refund Eligibility:</h2> */}
      <h3>Refund Eligibility</h3>
      
      
      <ul>
        <li>
          <p>&#8226; <strong>Cancellation Notice:</strong>To be eligible for a refund, you must request the cancellation at least 6 hours before the scheduled start time of your session.</p>
        </li>
        <li>
          <p>&#8226; <strong>Non-Refundable Cancellations:</strong>If you cancel your session less than 6 hours before the scheduled start time, unfortunately, no refund will be provided.</p>
        </li>
      </ul>

      <h3>How to Request a Refund</h3>
      
      
      <ul>
        <li>
          <p>&#8226; <strong>Submit a Request:</strong>To request a refund, please contact our support team via kaddawebcom@gmail.com.</p>
        </li>
        <li>
          <p>&#8226; <strong>Provide Details:</strong>Include your name and the reason for your cancellation.</p>
        </li>
        <li>
          <p>&#8226; <strong>Processing Time:</strong>Refund requests will be processed within 5-7 business days of receipt.</p>
        </li>
      </ul>

      <h3>Exceptions</h3>
      
      
      <ul>
        <li>
          <p>&#8226; <strong>Technical Issues:</strong>If a refund is requested due to a technical issue on our end, please provide detailed information, and we will review the situation on a case-by-case basis.</p>
        </li>
      </ul>


      <h3>Changes to the Policy</h3>
      
      <p>We reserve the right to update or amend this refund policy at any time. Any changes will be communicated through our website.</p>
      <p>For further assistance or inquiries, please contact our support team at kaddawebcom@gmail.com.</p>
      <p>Thank you for your understanding and cooperation.</p>
    </div>
  )
}

export default Content7Main