import { iconsImgs } from "../../utils/images";
import "./Content7Top.css";
import { auth } from "../../firebase"
import {
  setSignOutState
} from "../../features/user/userSlice";
import { useDispatch } from "react-redux";
import { useContext } from "react";
import { SidebarContext } from "../../context/sidebarContext";
import { useNavigate } from "react-router-dom";
import { CiLogout } from "react-icons/ci";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

const Content7Top = () => {
  const { toggleSidebar } = useContext(SidebarContext);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  
  const signout = () => {
    auth.signOut().then(() => {
        dispatch(setSignOutState());
        navigate("/");
      })
  }

  return (
    <div className="main-content-top">
        <div className="content-top-left">
            <button type="button" className="sidebar-toggler" onClick={() => toggleSidebar() }>
                <img src={ iconsImgs.menu } alt="" />
            </button>
            <h3 className="content-top-title-2">Refund Policy</h3>
        </div>
        <div className="content-top-btn">
        <button type="button" className="logout-btn" onClick={signout}>
            <h3 className="logout">Logout</h3>
            {/* <div className="iocn-2" >
             <CiLogout />
            </div> */}
            <ExitToAppIcon/>
          </button>
        </div>
    </div>
  )
}

export default Content7Top