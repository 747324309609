import "./Content3Main.css";
import check from "../../components/kad-logo5.png"
// import Upcomingclasses from "./Upcomingclasses";


const Content3Main = () => {
  return (
    <div>
      {/* <h3 className="main-text">Upcoming Classes</h3>
      <hr/> */}
      {/* <Upcomingclasses/> */}
      <div className='imgr2About'>
        <img src={check} className='imagge-2About'/>
      </div>
      <h4 className="textAbout">We made 'KaddaWeb' to enhance your English speaking skills !</h4>
      <h4 className="textAbout2">With our website, you can practice conversational English in real-time</h4>
      <h4 className="textAbout3">Whether you're a beginner or looking to polish your language proficiency, our sessions cater to all levels</h4>
      <h4 className="textAbout3">Say goodbye to traditional learning methods and dive into engaging conversations that boost your confidence and fluency</h4>
    </div>
  )
}

export default Content3Main