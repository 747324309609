import alert from "../assets/icons/alert.svg";
import budget from "../assets/icons/budget.svg";
import card from "../assets/icons/card.svg";
import home from "../assets/icons/home.svg";
import menu from "../assets/icons/menu.svg";


import person_two from "../assets/images/person_two.jpg";



export const iconsImgs = {
    alert, budget, card, home, menu}


export const personsImgs ={person_two};