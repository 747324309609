import "./Content6.css";
import Content6Top from '../../components/Content6Top/Content6Top';
import Content6Main from '../../components/Content6Main/Content6Main';



const Content6 = () => {
  return (
    <div className='main-content2'>
        <Content6Top/>
        <Content6Main/>
    </div>
  )
}

export default Content6