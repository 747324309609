import "./Content.css";
import { useEffect } from 'react';
import ContentTop from '../../components/ContentTop/ContentTop';
import ContentMain from '../../components/ContentMain/ContentMain';
import Sidebar from "../Sidebar/Sidebar";

const Content = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className='main-content2'>
       <ContentTop />
       <ContentMain />
    </div>
  )
}

export default Content