import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import Input from "./Input";
import Button from "./Button";
import {FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa"
import Icon from "./Icon";
import { useEffect, useState } from "react";
import "./Login.css";
import {auth} from "../firebase"
//import { useHistory } from "react-router-dom";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import check from "../components/kad-logo5.png"
import {
  selectUserName,
  selectUserPhoto,
  setUserLoginDetails,
  setSignOutState,
} from "../features/user/userSlice";

function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error1, setError1] = useState(false);
    const [error2, setError2] = useState(false);
    const [loading, setLoading] = useState(false);
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userName = useSelector(selectUserName);
    const userPhoto = useSelector(selectUserPhoto);


    useEffect(() => {
      auth.onAuthStateChanged(async (user) => {
        if (user) {
          setUser(user);
          // navigate("/Content");
        }
      });
    }, [userName]);

  

    const validateEmail = () => {
      setLoading(true);
      setError1(false);
      setError2(false);
      if(email && email.match(isValidEmail)){
        console.log('Valid email');
        signIn()
      }else{
        setError1(true);
        console.log('Invalid email');
        setLoading(false);
        return
      }
    }

    const setUser = (user) => {
      dispatch(
        setUserLoginDetails({
          name: user.displayName,
          email: user.email,
          photo: user.photoURL,
        })
      );
    };

    const signIn = () => {
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          setUser(userCredential.user)
          setLoading(false)
        })
        .catch((error) => {
          // console.log(error.code);
          setLoading(false);
          if(error.code === 'auth/invalid-credential'){
           console.log(error.code)
           setError2(true)
          }
        });
    };

    const goSignUp = () => {
      navigate("/SignUp"); 
    }

    // const signOutt = () => {
    //   signOut(auth).then(() => {
    //     console.log('success')
    //   }).catch((error) => {
    //     console.log(error.message)
    //   });
    // };


  const FacebookBackground = "linear-gradient(to right, #0546a0 0%, #663fb6 100%)";
  const InstagramBackground = "linear-gradient(to right, #a12ac4 0%, #ed586c 40%, #f0a853 100%)";
  const TwitterBackground = "linear-gradient(to right, #56c1e1 0%, #35a9ce 50%)";
  return (
  <div className="body-container">
    <Maincontainer>
      <div className='imgr2'>
        <img src={check} className='imagge-2'/>
      </div>
      <WelcomeText>ඔබගේ ගිණුමට පිවිසෙන්න</WelcomeText>
      <InputContainer>
        <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
        {error1 ? <div className="error-container">
         <h3 className="error1">*Invalid email</h3>
        </div> : null}
        <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
        {error2 ? <div className="error-container">
         <h3 className="error1">*Invalid password/User-Name</h3>
        </div> : null}
      </InputContainer>
      <ButtonContainer>
        {!loading ? <button type="submit" className="styledbutton" onClick={validateEmail}>Sign In</button> :
        <div className="styledbutton">
          <h5>Loading..</h5>
        </div>}
      </ButtonContainer>
      <h5 className="text-3">Forgot Password?</h5>
      <HorizontalRule/>
      <div className="abc">
       <h5 className="text-2">ගිණුමක් නොමැතිද? <Link className="link-1" to="/SignUp">නව ගිණුමක් සාදන්න</Link></h5>
      </div>
    </Maincontainer>
  </div>
  );
}

const Maincontainer =styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  // height: 80vh;
  width: 30vw;
  // background: rgba(255, 255, 255, 0.15);
  background: #ffffff;
  box-shadow:0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(8.5px);
  border-radius: 10px;
  color: #ffffff;
  /* text-transform: uppercase; */
  // letter-spacing: 0.15rem;
  @media only screen and (max-width:320px){
    width: 80vw;
    // height: 90vh;
    hr{
      margin-bottom: 0.3rem;
    }
    h4 {
      font-size: small;
    }
  }

  @media only screen and (min-width:360px){
    width: 80vw;
    // height: 90vh;

    h4 {
      font-size: small;
    }
  }

  @media only screen and (min-width:411px){
    width: 80vw;
    
  }

  @media only screen and (min-width:768px){
    width: 80vw;
    // height: 80vh;
  }

  @media only screen and (min-width:1024px){
    width: 70vw;
    // height: 50vh;
  }

  @media only screen and (min-width:1280px){
    width: 30vw;
    // height: 80vh;
  }
`;

const WelcomeText = styled.h2`
  margin: 0 0 0 0;
  font-size: 1rem;
  color: #525252;
  margin-bottom: 0.3rem;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 20%;
  width: 100%;
`;

const ButtonContainer = styled.div`
  margin: 2.5rem 0 2rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
const LoginWith = styled.h5`
    cursor: pointer;
`;

const NewAccount = styled.h5`
    cursor: pointer;
    text-align: center;
    margin-bottom: 2rem;
    font-weight: 500;
    font-family: Arial;
    
`;

const HorizontalRule = styled.hr`
    width: 90%;
    height: 0.3rem;
    border-radius: 0.8rem;
    border: none;
    margin: 1.5rem 0 1rem 0;
    background: linear-gradient(to right, #543e93 0%, #725bb1 79%);
    backdrop-filter: blur(25px);
`;

const IconsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 2rem 0 3rem 0;
  width: 80%;
`;

const ForgotPassword = styled.h4`
  cursor: pointer;
`


export default Login;
