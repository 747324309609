import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import "./Upcomingclasses.css";
import { useDispatch, useSelector } from "react-redux";
import { selectUserName } from "../../features/user/userSlice";
import { getFirestore,getDocs, collection, where, query, Timestamp, addDoc } from "firebase/firestore";
import { auth } from "../../firebase";
import emailIcon from "../email.png";
import packIcon from "../bird.png";
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

const Upcomingclasses = () => {
    
    let upcommings = [];
    let finisheds = [];

    const dispatch = useDispatch();
    const userName = useSelector(selectUserName);
    const navigate = useNavigate();


    let [storedValues, setStoredValues] = useState([]);
    let [userPackage, setUserPackage] = useState([]);
    let [Data, setDatta] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [valuee, setValuee] = useState('Time');
    const [valuee2, setValuee2] = useState('General');
    const [value, onChange] = useState(new Date());
    const [classDate, setClassDate] = useState();
    const [emailVerified, setEmailVerified] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const [loading3, setLoading3] = useState(true);
    const [loading4, setLoading4] = useState(true);
    const [done, setDone] = useState(false);
    const [error1, setError1] = useState(false);
    const [error2, setError2] = useState(false);
    const [error3, setError3] = useState(false);
    const [error4, setError4] = useState(false);
    const [error5, setError5] = useState(false);
    // const Datta = [{8:'8.00 AM - 8.20 AM'}, {9:'8.30 AM - 8.50 AM'}, {2:'2.00 PM - 2.20 PM'}, {10:'10.00 PM -10.20 PM'}]
    let Datta = ['8.00 AM - 8.20 AM', '8.30 AM - 8.50 AM', '9.00 AM - 9.20 AM', '9.30 AM - 9.50 AM', '10.00 AM - 10.20 AM', '10.30 AM - 10.50 AM', '11.00 AM - 11.20 AM',
'11.30 AM - 11.50 AM', '12.00 PM - 12.20 PM', '12.30 PM - 12.50 PM', '1.00 PM - 1.20 PM', '1.30 PM - 1.50 PM', '2.00 PM - 2.20 PM', '2.30 PM - 2.50 PM', '3.00 PM - 3.20 PM', '3.30 PM - 3.50 PM', '4.00 PM - 4.20 PM',
'4.30 PM - 4.50 PM','5.00 PM - 5.20 PM','5.30 PM - 5.50 PM','6.00 PM - 6.20 PM','6.30 PM - 6.50 PM','7.00 PM - 7.20 PM','7.30 PM - 7.50 PM', '8.00 PM - 8.20 PM', '8.30 PM - 8.50 PM', '9.00 PM - 9.20 PM', '9.30 PM - 9.50 PM']
    
    let types = ['General', 'IELTS', 'INTERVIEW']
    const db = getFirestore();
    const userUID = auth?.currentUser?.uid
    // const userEmailVerif = auth.currentUser.emailVerified
    const userEmail = auth?.currentUser?.email
    // const today = new Date()
    //     const hour = today.getHours()
    //     console.log(hour)
    // const year = today.getFullYear()
    // const month = today.getMonth()
      // the months are indexed starting with 0
    // const date = today.getDate()

    // const dateStr = `${year}-${month}-${date}`
    // console.log(dateStr)
    // useEffect(() => {
    //     db.collection("classCall").onSnapshot((snapshot) => {
    //       snapshot.docs.map((doc) => {
            
    //         switch (doc.data().classFinished) {
    //           case false:
    //             upcommings = [...upcommings, { id: doc.id, ...doc.data() }];
    //             break;
    
    //           case true:
    //             finisheds = [...finisheds, { id: doc.id, ...doc.data() }];
    //             break;
    //         }
    //       });
    
    //       dispatch(
    //         setCalls({
    //             upcoming: upcommings
    //         })
    //       );
    //     });
    //   }, [userName]);

    // const unsub = onSnapshot(doc(db, "classCall"), (doc) => {
    //     const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
    //     console.log(source, " data: ", doc.data());
    //   });

    //   const fetchDataFromFirestore = async () => {
    //     setStoredValues([]);
    //     const querySnapshot = await getDocs(collection(db, "classCall"));
    //     const temporaryArr = [];
    //     querySnapshot.forEach((doc) => {
    //         temporaryArr.push(doc.data());
    //     });
    //     setStoredValues(temporaryArr);
    //     console.log(storedValues)
    // };
    

    // const getTimeSlots = () => {
    //     const today = new Date()
    //     const hour = today.getHours() + 1
    //     console.log(hour)
    //     const slot = (hour - 8)*2
    //     const newArrayy = Datta.slice(slot)
    //     console.log(newArrayy)
    //     setDatta(newArrayy)
    // }
    const getTimeSlots = (date) => {
        if(date){
        const today = new Date()
        // console.log('today->', today)
        // console.log('selectedDate ->', selectedDate)
        if(date.toISOString().split('T')[0] === today.toISOString().split('T')[0]){
        const hour = today.getHours() + 1
        console.log(hour)
        const slot = (hour - 8)*2
        const newArrayy = Datta.slice(slot)
        console.log(newArrayy)
        if(newArrayy.length !== 0){
          setDatta(newArrayy)
        } else if (newArrayy.length === 0){
            let array = ['No Time Slots Available']
            setDatta(array)
        }
        } else {
            setDatta(Datta)
        }
        } else {
            return
        }
    }

    const getTimeSlots1 = (date) => {
        if(date){
        const today = new Date()
        // console.log('today->', today)
        // console.log('selectedDate ->', selectedDate)
        if(date.toISOString().split('T')[0] === today.toISOString().split('T')[0]){
        const hour = today.getHours() + 1
        console.log(hour)
        const slot = (hour - 8)*2
        const newArrayy = Datta.slice(slot)
        console.log(newArrayy)
        if(newArrayy.length !== 0){
          setDatta(newArrayy)
          setValuee(newArrayy[0])
        } else if (newArrayy.length === 0){
            let array = ['No Time Slots Available']
            let array2 = ['8.00 AM - 8.20 AM', '8.30 AM - 8.50 AM', '9.00 AM - 9.20 AM']
            setDatta(array)
            setValuee(array2[0])
        }
        } else {
            setDatta(Datta)
        }
        } else {
            return
        }
    }

    const handleChange = (e) => {
        setValuee(e.target.value);
      };

      const handleChange2 = (e) => {
        setValuee2(e.target.value);
      };
    
    const openInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
      };

    const chechEmailVerif = () => {
        setLoading2(true)
        if(auth.currentUser.emailVerified ){
            setEmailVerified(true)
            setLoading2(false)
        } else {
            setLoading2(false)
            return
        }
    }

    const fetchDataFromFirestore = async () => {
        
        try{
            // setStoredValues([]);
            setLoading(true)
            const colRef = collection(db, 'classCall')
            // const querySnapshot = await getDocs(collection(db, "classCall"));
            const q = query(colRef, where("userUID", "==", userUID))
            const querySnapshot = await getDocs(q)
    
            const temporaryArr = [];
    
            querySnapshot.forEach((doc) => {
                temporaryArr.push(doc.data());
            });
    
            setStoredValues(temporaryArr).then(setLoading(false))
            console.log(storedValues)
        }catch(e){
            console.log(e)
            setError1(true)
            setLoading(false)
        }
    };

    const getPackageofUser = async () => {
        
        try{
            // setStoredValues([]);
            setLoading3(true)
            const colRef = collection(db, 'userPlan')
            // const querySnapshot = await getDocs(collection(db, "classCall"));
            const q = query(colRef, where("userUID", "==", userUID))
            const querySnapshot = await getDocs(q)
    
            const temporaryArr = [];
    
            querySnapshot.forEach((doc) => {
                temporaryArr.push(doc.data());
            });
    
            setUserPackage(temporaryArr).then(setLoading3(false))
            console.log(userPackage)
        }catch(e){
            console.log(e)
            // setError1(true)
            setLoading3(false)
        }
    };

    

    const scheduleaClass = async () => {
        
        try{
            setLoading4(true)
            setError2(false)
            setError3(false)
            const colRef = collection(db, 'scheduleClassRequest')
            const q = query(colRef, where("userUID", "==", userUID))
            const querySnapshot = await getDocs(q)
            const temporaryArr = [];
            querySnapshot.forEach((doc) => {
                temporaryArr.push(doc.data());
            });
            if(temporaryArr.length !== 0){
                setError2(true)
                setLoading4(false)
                return
            }else if(temporaryArr.length === 0){
                const colRef = collection(db, 'scheduleClassRequest')
                var myTimestamp = Timestamp.fromDate(new Date());

                addDoc(colRef, {
                    userUID: userUID,
                    userName: userEmail,
                    requestCreatedTime: myTimestamp,
                    classDate: selectedDate,
                    classTime: valuee,
                    classType: valuee2
                   })
                
                setDone(true)
                setLoading4(false)
            } else {
                setError3(true)
                setLoading4(false)
                return
            }
        }catch(e){
            console.log(e)
            setError3(true)
            setLoading4(false)
        }
    }

    const func1 = () => {
      setError4(false)
      setError5(false)
      if(selectedDate){
        console.log(selectedDate)
        if(Data[0] !== 'No Time Slots Available'){
            scheduleaClass()
            // console.log(valuee);
        } else {
          setError5(true)
        }
      } else {
        setError4(true)
      }
    // console.log(valuee);
    }

    useEffect(() => {
        setError1(false);
        fetchDataFromFirestore();
        chechEmailVerif();
        getPackageofUser();
        getTimeSlots1(new Date());
      }, []);

    
    // const [classes]=useState([
    //     {
    //         title:'01.23.2024',
    //         text:'You can join a session only at the allocated time'
    //     },

    //     {
    //         title:'01.25.2024',
    //         text:'You can join a session only at the allocated time'
    //     },

    //     {
    //         title:'01.27.2024',
    //         text:'You can join a session only at the allocated time'
    //     },

    //     {
    //         title:'01.29.2024',
    //         text:'You can join a session only at the allocated time'
    //     }
    // ])
  return (
    <div>
        <section>
            <div className="container">

               {/* {loading2 ? null : (!emailVerified ? <div className='msgContaner-1'>
                 <div className='image-1'>
                  <img src={emailIcon} className='img-1'/>  
                 </div>       
                 <div className='textContainer-1'>
                  <h3 className='msg-1'>We have send an email to {userEmail}. Please verify your email</h3>
                 </div>
                </div> : null)} */}


                {loading ? null : (storedValues.length === 0 ?
                (loading3 ? null : (userPackage.length !== 0 ? (userPackage[0].activStatus === 2 ? <div className='msgContainer-3'>
                {/* <div className='image-2'>
                  <img src={packIcon} className='img-2'/>  
                </div> 
                 
                  <h3 className='tex-1'>You have not activated any packages</h3>
                 
                 <div className='buttonHolder3'>
                     <button className ='btn4' onClick={() => navigate("/Content2")}>See Packages</button>
                 </div>
                 <h3 className='tex-2'>OR</h3>
                 <div className='ruler'></div>
                 <h3 className='tex-2'>Contact Us: 077311133</h3> */}
                 {done ? <h3 className='tex-4'>You have succesfully requested a schedule we will approve it within few minutes. Thank you</h3> : null}
                 {!done ? <h3 className='tex-3'>පන්ති වේලාව තෝරන්න / Let's schedule a session</h3> : null}
                 {!done ? <div className='input-Container'>
                 
                  {/* <input type="date" className='inpu1' min={new Date().toISOString().split('T')[0]} onfocus={this.showPicker()} onChange={(event) => setClassDate(event.target.value)}></input> */}
                  <DatePicker selected={selectedDate} className='inpu1' onChange={date=>{setSelectedDate(date); getTimeSlots(date)}} placeholderText='Click to add date' 
                  dateFormat='yyyy/MM/dd'
                  minDate={new Date()}
                  />
    
                 
            
                 {error4 ? <h4 className='error-tex'>select a valid date</h4> : null}
                 {/* <select className='inpu2'>
                    <option>5:00 - 5:20</option>
                    <option>5:30 - 5:50</option>
                 </select> */}
                 <select className='inpu2' onChange={handleChange} onClick={handleChange} value={valuee}>{
    Data.map( (x,y) => 
      <option key={y}>{x}</option> )
  }</select>

<select className='inpu2' onChange={handleChange2} value={valuee2} onClick={handleChange2}>{
    types.map( (x,y) => 
      <option key={y}>{x}</option> )
  }</select>
                {/* <h4 className='error-tex'>{classDate}</h4> */}
                {error5 ? <h4 className='error-tex'>select a valid time</h4> : null}
                {error3 ? <h4 className='error-tex'>error try again</h4> : null}
                {error2 ? <h4 className='error-tex'>You have already requested to schedule a class</h4> : null}
                <button className='btn' onClick={()=>func1()}>තෝරන්න / Schedule</button>
                </div> : null}
                </div> : <div className='msgContainer-2'> 
                <div className='image-2'>
                 <img src={packIcon} className='img-2'/>  
                </div> 
               
                <h3 className='tex-1'>You have not activated any packages</h3>
                <h3 className='tex-1'>ඔබ තවම පැකේජයක් සක්‍රිය කර නොමැත</h3>
               
                <div className='buttonHolder3'>
                   <button className ='btn4' onClick={() => navigate("/Packages")}>පැකේජයක් තෝරන්න</button>
                </div>
                {/* <h3 className='tex-2'>OR</h3> */}
                {/* <div className='ruler'></div> */}
                <h3 className='tex-2'>අමතන්න: 0773160677</h3>
                </div>) :
                <div className='msgContainer-2'> 
                <div className='image-2'>
                 <img src={packIcon} className='img-2'/>  
                </div> 
               
                <h3 className='tex-1'>You have not activated any packages</h3>
                <h3 className='tex-1'>ඔබ තවම පැකේජයක් සක්‍රිය කර නොමැත</h3>
                <div className='buttonHolder3'>
                   <button className ='btn4' onClick={() => navigate("/Packages")}>පැකේජයක් තෝරන්න</button>
                </div>
                {/* <h3 className='tex-2'>OR</h3> */}
                <div className='ruler'></div>
                <h3 className='tex-2'>අමතන්න: 0773160677</h3>
                </div>
                )) : 
                <div className="cards">
                    {
                        storedValues.map((card, i)=>(

                    <div key={i} className="card">
                        <h3>{card.instructorName}</h3>
                        <p>
                            {card.userName}
                        </p>
                        <button className='btn' onClick={() => openInNewTab(card.url)}>Join</button>
                    </div>
                        ))
                    }
                </div>)}
            </div>
        </section>
    </div>
  )
}

export default Upcomingclasses